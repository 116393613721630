import request from '@/utils/request'

// 获取所有渠道类型
// export function getCountryPaymentsList (data) {
//   return request({
//     url: '/admin/country-payments',
//     method: 'get',
//     data
//   })
// }
// 国家列表
export function getCountryPaymentsList (data) {
  return request({
    url: '/admin/country-payments',
    data,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
// 编辑
export function getUpdateCountryPayments (id, data) {
  console.log(id, data, 'data')
  return request({
    url: `admin/country-payments/${id}`,
    data,
    method: 'put',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
// 新增
export function getCreateCountryPayments (data) {
  console.log(data, 'data1')
  return request({
    url: 'admin/country-payments',
    data,
    method: 'post',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
// 开关
export function getCountryPaymentStatus (id, data) {
  return request({
    url: `admin/country-payments/open/${id}`,
    data,
    method: 'put',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export const getCountryPaymentsById = function (url, params, optionsSource) {
  let paramData
  if (params && typeof params === 'object') {
    paramData = params
  } else {
    paramData = undefined
  }
  return request({
    url,
    paramData,
    method: 'get',
    optionsSource,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
  // return https().request(url, Method.GET, paramData, ContentType.form, optionsSource)
}
// export const getCountryPaymentStatus = async (url, val) => {
//   await getCountryPaymentStatus(url, val)
// }admin/country-payments/{id}
