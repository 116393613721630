<template>
  <Layout>
    <!-- <el-row> -->
    <router-link
      class="payButtonAdd"
      :to="{name:'paymentPageConfigurationAdd'}"
    >
      <el-button

        type="primary"
      >
        新增国家
      </el-button>
    </router-link>
    <!-- </el-row> -->
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%; margin-top: 25px; margin-bottom: 20px"
      border
      class="commonTable"
    >
      <el-table-column
        label="国家"
        prop="region_code"
      />
      <el-table-column
        label="支付方式"
        prop="payment_methods"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="controls">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="上架"
              inactive-text="下架"
              style="margin-left: 10px; margin-bottom: 10px"
              @change="handleOff(scope.row)"
            />
            <!-- <router-link :to="{path:`/CommodityManagement/paymentPageConfigurationEdit/${scope.row.id}`,query:{row:scope.row}}"> -->
            <router-link :to="{name:`paymentPageConfigurationEdit`, params: {id: scope.row.id}}">
              <span
                class="edit"
              >
                编 辑
              </span>
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </Layout>
</template>

<script>
import {
  getCountryPaymentsList,
  getCountryPaymentStatus
} from '@/api/paymentModeManagement.js'
export default {
  components: {},
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      getCountryPaymentsList()
        .then(res => {
          this.tableData = res.map(data =>
            ({ ...data, payment_methods: data.payment_methods.join(',') }))
          this.tableData.sort((a, b) => {
            if (a.region_code === 'DEFAULT') return -1 // 将包含DEFAULT的对象放在前面
            if (b.region_code === 'DEFAULT') return 1 // 将包含DEFAULT的对象放在前面
            return 0
          })
        }).finally(() => { this.loading = false })
    },
    // 下架
    async handleOff (val) {
      const str = val.id.toString()
      const param = {
        status: val.status
      }
      try {
        await getCountryPaymentStatus(str, param)
        // getList()
      } catch (err) {
        this.$message.error(err.response.data.message)
        this.getList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.payButtonAdd {
  width: 100px;
}
.commonTable {
  .el-table__row {
    .cell {
      // display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .controls {
    display: flex;
    margin-top: 14px;
    flex-direction: row;
    a{
      width: auto;
      color: #000000;
      margin-left: 150px;
      cursor: pointer;
    }
  }
}
</style>
